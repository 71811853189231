import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

import PlansStyle from '@app/components/Plans/style';

import { samplePlanData } from '@app/utils/sample-plan';
import Link from 'next/link';
// import PayPalPlans from '@app/components/Paypal'
import { FormattedMessage, useIntl } from 'react-intl';

const Plans = (): React.ReactElement => {
  const intl = useIntl();

  return (
    <PlansStyle>
      <Container fluid>
        <Row className="justify-content-md-center row_info">
          <Col xs sm="12" className="info">
            <div className="divider">
              <div className="one"></div>
              <div className="two"></div>
            </div>

            <div className="title" style={{ textTransform: 'uppercase' }}>
              <FormattedMessage id="landingPage.plans.title" />
            </div>
          </Col>
        </Row>

<Row className="justify-content-md-center row_content">
        <Col xs sm="12" lg="10" xl="10">
          {/*<PayPalPlans></PayPalPlans>*/}
          <Row className="justify-content-md-center row_center">
            {samplePlanData.map((it, idxPlan) => {
              return (
                <Col key={it.name} xs="12" sm="12" md="6" lg="4" xl="4" className="row_card">
                  <Link
                    href={{
                      pathname: '/plan',
                      query: { idxPlan },
                    }}
                  >
                    <div className="card_item">
                      <div className="card-image"></div>
                      <div className="title">{intl.formatMessage({ id: it.name })}</div>
                      {/* <div className="subtitle">TEXTO DE EXEMPLO</div> */}
                      <div className="cards_divider"></div>
                      <div className="card_text">{it.featuresArray.map(text => (
                        <ul>
                          <li>{intl.formatMessage({ id: text })}</li>
                        </ul>
                      ))}</div>
                        {/* <FontAwesomeIcon icon={faArrowRight} className="icon" size="2x" /> */}
                      </div>
                    </Link>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </PlansStyle>
  );
};

export default Plans;
